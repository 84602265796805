import type { FC } from 'react'
import PlaceholderKorag from './PlaceholderKorag'
import { MinimalistButton } from './buttons/MinimalistButton'

const Error: FC<{
  errorTitle: string
  message: string
  ctaHref: string
  ctaTitle: string
}> = ({ errorTitle, message, ctaHref, ctaTitle }) => {
  return (
    <div className="items-center justify-center w-full h-full p-4">
      <div className="m-auto">
        <h1 className="text-center text-[7rem] font-bold">{errorTitle}</h1>
        <PlaceholderKorag
          callToAction={
            <MinimalistButton
              props={{
                mainColor: 'white',
                buttonText: ctaTitle,
                href: ctaHref,
              }}
            />
          }
        >
          <span className="text-center">{message}</span>
        </PlaceholderKorag>
      </div>
    </div>
  )
}

export default Error
