import type { ReactElement } from 'react'
import Error from '@g4g/ui/src/internal/Error'

export default function ErrorPage() {
  return (
    <Error
      errorTitle="404"
      message="Sorry, we were unable to find what you were looking for"
      ctaHref="/deals"
      ctaTitle="Browse Deals"
    />
  )
}

ErrorPage.getLayout = function getLayout(page: ReactElement) {
  return <div>{page}</div>
}
